import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [];

export const dictionary = {
		"/(marketing)": [8,[2]],
		"/config": [17],
		"/console": [18,[3]],
		"/console/new": [49,[3]],
		"/console/new/dev": [50,[3]],
		"/console/select": [51,[3]],
		"/console/[subdomain]": [19,[3,4]],
		"/console/[subdomain]/billing": [20,[3,4]],
		"/console/[subdomain]/integrations": [21,[3,4,5]],
		"/console/[subdomain]/integrations/hyvor-talk": [22,[3,4,5]],
		"/console/[subdomain]/pages": [23,[3,4]],
		"/console/[subdomain]/posts": [24,[3,4]],
		"/console/[subdomain]/posts/[postId]": [25,[3,4]],
		"/console/[subdomain]/settings": [26,[3,4,6]],
		"/console/[subdomain]/settings/api-keys": [27,[3,4,6]],
		"/console/[subdomain]/settings/code": [28,[3,4,6]],
		"/console/[subdomain]/settings/color-mode": [29,[3,4,6]],
		"/console/[subdomain]/settings/comments": [30,[3,4,6]],
		"/console/[subdomain]/settings/danger": [31,[3,4,6]],
		"/console/[subdomain]/settings/hosting": [32,[3,4,6]],
		"/console/[subdomain]/settings/languages": [33,[3,4,6]],
		"/console/[subdomain]/settings/media": [34,[3,4,6]],
		"/console/[subdomain]/settings/navigation": [35,[3,4,6]],
		"/console/[subdomain]/settings/post-content": [36,[3,4,6]],
		"/console/[subdomain]/settings/redirects": [37,[3,4,6]],
		"/console/[subdomain]/settings/routes": [38,[3,4,6]],
		"/console/[subdomain]/settings/seo": [39,[3,4,6]],
		"/console/[subdomain]/settings/tags": [40,[3,4,6]],
		"/console/[subdomain]/settings/users": [41,[3,4,6]],
		"/console/[subdomain]/settings/webhooks": [42,[3,4,6]],
		"/console/[subdomain]/theme": [43,[3,4]],
		"/console/[subdomain]/tools": [44,[3,4,7]],
		"/console/[subdomain]/tools/export": [45,[3,4,7]],
		"/console/[subdomain]/tools/import": [46,[3,4,7]],
		"/console/[subdomain]/tools/link-analysis": [47,[3,4,7]],
		"/console/[subdomain]/tools/media": [48,[3,4,7]],
		"/(marketing)/customers": [9,[2]],
		"/(marketing)/docs/[[slug]]": [10,[2]],
		"/(marketing)/for/[slug]": [11,[2]],
		"/(marketing)/integrations": [12,[2]],
		"/(marketing)/integrations/[slug]": [13,[2]],
		"/(marketing)/pricing": [14,[2]],
		"/(marketing)/theme-service": [15,[2]],
		"/(marketing)/themes": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';